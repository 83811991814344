// Kick start the processes

$( document ).ready( function(){

	$( '.js-smooth-scroll' ).click(function(){
   	$( 'html, body' ).animate({ scrollTop: $( $( this ).attr( 'href' ) ).offset().top }, 'slow' );

    return false;
	});

	$( '.toolbar-button--toggle' ).on( 'touchstart click', function touchStart( event ) {
    $( this ).toggleClass( 'toolbar-button--toggle--active' );

   	event.preventDefault();
	});

	// Date Range Picker
	( function( $, window, document, undefined ) {

		var $daterangepicker = $('.js-daterangepicker');

		if( $daterangepicker.length ){
			$daterangepicker
				.dateRangePicker( {
					format: 'DD/MM/YYYY',
					startDate: moment().format('DD/MM/YYYY'),
					startOfWeek: 'monday',
					inline: true,
					container: '#holiday-daterange-picker',
					alwaysOpen: true
					//TODO: use beforeShowDay: function(t) to disable already selected dates
				} )
				.on( 'datepicker-change', function( event, obj ) {
					var $form = $(this).closest('form'),
							$date_start = $form.find('#date_start'),
							$date_end = $form.find('#date_end');

					$date_start.val( moment( obj.date1 ).format( 'YYYYMMDD' ) );
					$date_end.val( moment( obj.date2 ).format( 'YYYYMMDD' ) );

					removeInlineError( $daterangepicker );
				} );
		}

		/**
		 * Create an inline error message to display to the user.
		 * The error message will be inserted after the element passed.
		 * @param  {jQuery} $after  The element to insert the error message after.
		 * @param  {string} message The message to show.
		 */
		function showInlineError( $after, message ) {
		  var $msg_container = $after.next('.js-form-message'),
		      msg_container_html = '<p class="form__group form__message form__message--error js-form-message js-form-message--error">' + message + '</p>';

		  if ( $msg_container.length > 0 ) {

		    $msg_container.html( message );

		  } else {

		    $msg_container = $( msg_container_html );
		    $msg_container.insertAfter( $after );

		  }

		  // It may be hidden so make sure it is not
		  $msg_container.show();
		}

		/**
		 * Remove an inline error if it exists after a certain element.
		 * @param  {jQuery} $after The error will be removed if it occurs directly after this element.
		 */
		function removeInlineError( $after ) {
			var $msg_container = $after.next('.js-form-message');

			if ( $msg_container.length ) {
				$msg_container.remove();
			}
		}

		$('#request-holiday-form').on( 'submit', function( event ) {
			var $form = $(this),
					$date_start = $form.find('#date_start'),
					$date_end = $form.find('#date_end'),
					proceed = true;

			if ( $date_start.is('[required]') && $date_start.val() === '' ) {
        $date_start.addClass('form__input--has-error');
        showInlineError( $daterangepicker, 'Choose your dates' );

        proceed = false;
      }

      if ( $date_end.is('[required]') && $date_end.val() === '' ) {
        $date_end.addClass('form__input--has-error');
        showInlineError( $daterangepicker, 'Choose your dates' );

        proceed = false;
      }

      if ( !proceed ) {
      	event.preventDefault();
      	return false;
      }
		} );

	} )( jQuery, window, document );

	// Slick slider module
	( function( $, window, document, undefined ) {

		$( '.slider-team' ).slick({
			slidesToShow: 4,
			slidesToScroll: 1,
			infinite: true,
			arrows: false,
			autoplay: true,
			speed: 1800,
			cssEase: 'linear',
			autoplaySpeed: 1,
			easing: 'linear',
			pauseOnHover: true,
		  responsive: [
		    {
		      breakpoint: 1024,
		      settings: {
		        slidesToShow: 3,
		      }
		    },
		    {
		      breakpoint: 800,
		      settings: {
		        slidesToShow: 2,
		        speed: 3600
		      }
		    },
		    {
		      breakpoint: 480,
		      settings: {
		        slidesToShow: 1,
		        slidesToScroll: 1,
		        speed: 5000
		      }
		    }
		  ]
		});

		$( '.slider-videos' ).slick({
			slidesToShow: 3,
			slidesToScroll: 1,
			infinite: true,
			arrows: true,
			autoplay: true,
			speed: 1800,
			cssEase: 'linear',
			autoplaySpeed: 1,
			easing: 'linear',
			pauseOnHover: true,
		  responsive: [
		    {
		      breakpoint: 1024,
		      settings: {
		        slidesToShow: 3,
		      }
		    },
		    {
		      breakpoint: 940,
		      settings: {
		        arrows: false,
		      }
		    },
		    {
		      breakpoint: 800,
		      settings: {
		        slidesToShow: 2,
		        arrows: false,
		      }
		    },
		    {
		      breakpoint: 480,
		      settings: {
		        slidesToShow: 1,
		        arrows: false
		      }
		    }
		  ]
		});

		/**
		 * Repeat text at the start of a string until the string reaches a certain length.
		 * @param  {String} string  The string to pad.
		 * @param  {String} padWith The text to repeat at the start of string.
		 * @param  {Number} length  The desired length of the return string.
		 * @return {String}         The padded string.
		 */
		function padLeft( string, padWith, length ) {
			// Cast params to string
			string += "";
			padWith += "";

			// Return early if the string is the correct length already
			if ( string.length >= length ) {
				return string;
			}

			while	( string.length < length ) {
				string = padWith + string;
			}

			return string;
		}

		function updateSliderControls( selector, action ) {
			var $target = $( selector ),
					$controls = $( '[data-slider-control]' ),
					$index = $controls.filter( '[data-slider-control="slide-index"]' ).filter( '[data-slider-target="' + selector + '"]' );

			// Update index
			if ( $index.length ) {
				$index.text( padLeft( $target.customslider( 'currentIndex' ) + 1, '0', 2 ) );
			}
		}

		$( '[data-slider-action]' ).click( function( event ) {
			var $this = $(this);

			// Disable button until transition has finished
			$(this).attr( 'disabled', 'disabled' );
			setTimeout(function() {
				$this.removeAttr( 'disabled' );
			}, 600);

			var action = $this.data( 'sliderAction' ),
					targetSelector = $this.data( 'sliderTarget' ),
					$target = $( targetSelector );

			if ( $target.length && $target.hasClass( 'customslider-initialised' ) ) {

			  $target.customslider( action );

			  // Update controls
			  updateSliderControls( targetSelector, action );

			}

			event.preventDefault();
			return false;
		});


	} )( jQuery, window, document );

	// Job Filter logic
	$('a[data-job-type-filter]').click( function( event ) {

		var $this = $(this),
				job_type = $this.data('jobTypeFilter'),
				$allJobs = $('[data-job-type]');
				$relatedJobs = $allJobs.filter('[data-job-type="' + job_type + '"]');

		if ( $relatedJobs.length ) {

			$allJobs.hide().attr( 'aria-hidden', true );
			$allJobs.css( 'margin-top', '' );

			$relatedJobs.eq(0).css( 'margin-top', 0 );
			$relatedJobs.show().attr( 'aria-hidden', false );

		} else {

			if ( '*' == job_type ) {
				$allJobs.css( 'margin-top', '' );
				$allJobs.show().attr( 'aria-hidden', false );
			} else {
				alert( 'No ' + job_type + ' jobs found.' );
			}

		}

		$this.parent().siblings('li').removeClass( 'is-active' );
		$this.parent().addClass( 'is-active' );

		event.preventDefault();
		return false;

	} );

	// Case Studies filter
	( function( $, window, document, undefined ) {

		var $caseStudies = $('.js-case-study'),
				$filters = $('[data-sector-term-filter]'),
				$filterButton = $('.js-case-study-filter-button'),
				$foundPosts = $('.js-found-posts'),
				activeTermIDs = [];

		function updatePostCount( count ) {
			if ( $foundPosts.length ) {
				$foundPosts.html( _n( count + ' case study found', count + ' case studies found', count ) );
			}

			if ( 0 === count ) {
				$('.js-case-study-none').show();
			} else {
				$('.js-case-study-none').hide();
			}
		}

		function hideAllCaseStudies() {
			$caseStudies.hide();
		}

		function showAllCaseStudies() {
			$caseStudies.show();
		}

		function showMatchingCaseStudies() {
			$caseStudies.each( function( index, node ) {

				var $this = $(this),
						sectors = JSON.parse( $this.attr('data-sectors') );

				$.each( activeTermIDs, function( key, value ) {

					if ( $.inArray( value, sectors ) > -1 ) {
						$this.show();
					}

				} );

			} );
		}

		if ( $caseStudies.length ) {

			$filters.on( 'click', function( event ) {

				var $this = $(this),
						termID = parseInt( $this.attr('data-sector-term-filter') ),
						index;

				if ( $.inArray( termID, activeTermIDs ) > -1 ) {

					index = activeTermIDs.indexOf( termID );
					if ( index > -1 ) {
						activeTermIDs.splice( index, 1 );
					}

				} else {

					activeTermIDs.push( termID );

				}

				if ( activeTermIDs.length > 0 ) {

					hideAllCaseStudies();
					showMatchingCaseStudies();

				} else {

					showAllCaseStudies();

				}

				updatePostCount( $caseStudies.filter(':visible').length );

			} );

			$filterButton.closest( '.sectors-filter' ).css( 'min-height', 0 );
			$filterButton.hide();

		}

	} )( jQuery, window, document );



});
