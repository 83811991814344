( function( $, window, document, undefined ) {

  ( function ( factory ) {

    // jQuery plugin
    $.fn.multistepform = factory;

    // Allow user overriden defaults
    // Main plugin defaults are created below
    $.fn.multistepform.defaults = {};

  } )( function() {

    "use strict";

    // class: MultiStepForm
    // Constructor
    var MultiStepForm = function( $wrapper, options ) {
      // Init passed
      this.$wrapper = $wrapper;
      this.options = options;

      // Init dynamic properties
      this.identifier = this.$wrapper.data('multistepform');
      this.$track = this.$wrapper.find( '[data-multistepform-track]' );
      this.$steps = this._getSteps();
      this.$counters = this._getCounters();

      this._currentIndex = 1;

      this.$prevButton = this.$wrapper.find( '[data-multistepform-navigation="prev"]' );
      this.$nextButton = this.$wrapper.find( '[data-multistepform-navigation="next"]' );

      // Bind all events
      this._bindEvents();

      // Init states
      this._init();
    };

    // Prototype methods
    // Methods prefixed with _ are private, any others are public and can be called outside of plugin, by $element.multistepform( 'methodName', param1, param2 );

    /**
     * Get a set of jQuery elements representing the steps of the form.
     * @return {jQuery} The step elements inside the form.
     */
    MultiStepForm.prototype._getSteps = function() {
      return this.$wrapper.find( '[data-multistepform-step]' );
    };

    /**
     * Get a set of jQuery elements representing the steps of the form.
     * @return {jQuery} The step elements inside the form.
     */
    MultiStepForm.prototype._getCounters = function() {
      return this.$wrapper.find( '[data-multistepform-step-counter]' );
    };

    /**
     * Bind the event handlers needed for this class.
     * @return this
     */
    MultiStepForm.prototype._bindEvents = function() {
      var instance = this;

      // Bind all events for the form to the wrapper itself
      // as delegated/bubbled events

      instance.$wrapper.on( 'click', '[data-multistepform-navigation="prev"]', function( event ) {
      	// Check for disabled (for legacy)
      	if ( $(this).is('[disabled]') ) {
      		event.preventDefault();
      		return false;
      	}

        instance.prev();
      } );

      instance.$wrapper.on( 'click', '[data-multistepform-navigation="next"]', function( event ) {
      	// Check for disabled (for legacy)
      	if ( $(this).is('[disabled]') ) {
      		event.preventDefault();
      		return false;
      	}

        instance.next();
      } );

      instance.$wrapper.on( 'click', '[data-multistepform-navigation="reset"]', function( event ) {
        // Check for disabled (for legacy)
        if ( $(this).is('[disabled]') ) {
          event.preventDefault();
          return false;
        }

        instance.$wrapper.find( 'textarea, input' ).each( function() {
          $(this).val( '' );
          $(this).prop( 'checked', false );
        } );
        instance._resetButtons();
        instance.goTo( 1 );
      } );

      return this;
    };

    MultiStepForm.prototype._resetButtons = function() {
      this.$prevButton.show();
      this.$nextButton.show();

      this.$prevButton.text( 'Previous' );
      this.$nextButton.text( 'Next' );

      this.$prevButton.attr( 'data-multistepform-navigation', 'prev' );
      this.$nextButton.attr( 'data-multistepform-navigation', 'next' );

      this.$prevButton.removeAttr( 'data-original-text' );
      this.$nextButton.removeAttr( 'data-original-text' );
    };

    /**
     * Run any custom initialisation steps needed.
     * @return this
     */
    MultiStepForm.prototype._init = function() {
    	// Set the height on the list of steps
    	this.$track.parent().css( 'height', this._getHeightOfStep( this._currentIndex ) );

      // Update the tabindex for all hidden steps
      this._setTabIndex( this.getCurrentIndex() );

    	return this;
    };

    /**
     * Get the height of an individual step.
     * @param  {Number} index The index of the step to get the height for.
     * @return {Number}       The height of the step.
     */
    MultiStepForm.prototype._getHeightOfStep = function( index ) {
    	var $step = this.getStepByIndex( index );

    	return $step.outerHeight();
    };


    /**
     * Set the tab index for the active step so that inactive steps cannot be tabbed to.
     * @param {Number} index The index for the current step.
     */
    MultiStepForm.prototype._setTabIndex = function( index ) {
      var $step = this.getStepByIndex( index );

      this.$wrapper.find( 'input, textarea' ).attr( 'tabindex', '-1' );
      $step.find( 'input, textarea' ).attr( 'tabindex', '0' );

      return this;
    };

    /**
     * Move to the previous step.
     * @return this
     */
    MultiStepForm.prototype.prev = function() {
    	var index = this.getCurrentIndex() - 1;

    	if ( index > 0 ) {
	    	this.goTo( index );
    	}

    	return this;
    };

    /**
     * Move to the next step.
     * If the next step is the final step, the form will also trigger a submit event.
     * @return this
     */
    MultiStepForm.prototype.next = function() {
    	var index = this.getCurrentIndex() + 1;

      if ( index <= this.$steps.length ) {
      	this.goTo( index );
      }

    	return this;
    };

    /**
     * Update the height of the $track based on the current step.
     * @return this
     */
    MultiStepForm.prototype._updateHeight = function() {
      this._updateHeightByIndex( this.getCurrentIndex() );

      return this;
    };

    /**
     * Update the height of the $track to the height of a specific step.
     * @param  {Number} index The index of the step to get the height from.
     * @return this
     */
    MultiStepForm.prototype._updateHeightByIndex = function( index ) {
      var newHeight;

      if ( this.options.adaptiveHeight ) {
        newHeight = this._getHeightOfStep( index );

        // Animate the height of the track wrapper
        this.$track.parent().animate( {
          height: newHeight
        } );
      }

      return this;
    };

    /**
     * Go to a specific step index within the form.
     * @param  {Number} index The index to go to.
     * @param {Boolean} force Set to true to force the change without checking validation.
     * @return this
     */
    MultiStepForm.prototype.goTo = function( index, force ) {
      // Make sure that the current step is validated before changing
      if ( undefined === force && !this._validateStepChange( index ) ) {
        return this;
      }

    	var
          // Get the percentage needed for a transform on the track
    			percentage = ( -25.12315 * index ) + 25.12315,

    			$currentStep = this.getCurrentStep(),
    			$currentCounter = this._getCurrentCounter(),
    			$newStep = this.getStepByIndex( index ),
    			$newCounter = this._getCounterByIndex( index );

    	this._updateHeightByIndex( index );

    	// Animate the track to the new location
    	this.$track.css( {
    		'-webkit-transform': 'translateX(' + percentage + '%)',
		    		'-ms-transform': 'translateX(' + percentage + '%)',
				    		'transform': 'translateX(' + percentage + '%)',
    	} );

    	// Maintain the aria & current state of each step
    	$currentStep.attr( {
    		'aria-hidden': 'true',
    	} );

    	$currentCounter.attr( {
    		'data-multistepform-active': 'false',
    	} );

    	$newStep.attr( {
    		'aria-hidden': 'false',
    	} );

    	$newCounter.attr( {
    		'data-multistepform-active': 'true',
    	} );

      // Update tabindex
      this._setTabIndex( index );

    	// Update the state of the buttons
    	this._updateButtonState( index );

    	// Finally finish up
    	this._currentIndex = index;

      // Check if the form should be submitted
      // Based on the final step having a specific attribute at the moment
      this._maybeSubmit();

      // Transfer focus
      setTimeout(function() {
        $newStep.find('input').eq(0).focus();
      }, 250);

    	return this;
    };

    MultiStepForm.prototype._validateStepChange = function( index ) {
      var $currentStep = this.getCurrentStep(),
          eventData = this._getEventData( $currentStep ),
          event;

      // Only need to trigger an event if we're going forwards in steps
      if ( index > this.getCurrentIndex() ) {

        event = jQuery.Event( 'multistepform.validateStep' );

        // Validate before changing step
        this.$wrapper.trigger( event, eventData );

        // Only move to next step if the validation has passed
        if ( event.isDefaultPrevented() ) {
          // Update height to show any errors
          this._updateHeight();
          return false;
        }

      }

      return true;
    };

    MultiStepForm.prototype._maybeSubmit = function() {
      var $currentStep = this.getCurrentStep(),
          eventData = this._getEventData( $currentStep ),
          event;

      // Only need to trigger an event if the attribute exists
      if ( $currentStep[0].hasAttribute( 'data-multistepform-submit' ) ) {
        event = jQuery.Event( 'multistepform.submitForm' );

        this.$wrapper.trigger( event, eventData );
      }

      return true;
    };

    /**
     * Get any data we want to pass with an event.
     * @param  jQuery $step The element representing a step within the form.
     * @return {Object} An object containing the data to pass to the event.
     */
    MultiStepForm.prototype._getEventData = function( $step ) {
      return {
        id: this.identifier,
        index: $step.attr('data-multistepform-step'),
        inputs: $step.find('input'),
        wrapper: this.$wrapper
      };
    };

    /**
     * Get the current step element.
     * @return {jQuery}
     */
    MultiStepForm.prototype.getCurrentStep = function() {
    	return this.$steps.filter( '[aria-hidden="false"]' );
    };

    /**
     * Get the current step's counter element.
     * @return {jQuery}
     */
    MultiStepForm.prototype._getCurrentCounter = function() {
    	return this.$counters.filter( '[data-multistepform-active="true"]' );
    };

    /**
     * Get the current step's index.
     * @return {jQuery}
     */
    MultiStepForm.prototype.getCurrentIndex = function() {
    	return parseInt( this._currentIndex );
    };

    /**
     * Get a step element by index.
     * @param {Number} index The index to get the step element for.
     * @return {jQuery}
     */
    MultiStepForm.prototype.getStepByIndex = function( index ) {
    	return this.$steps.filter( '[data-multistepform-step="' + index + '"]' );
    };

    /**
     * Get a step counter element by index.
     * @param {Number} index The index to get the step counter element for.
     * @return {jQuery}
     */
    MultiStepForm.prototype._getCounterByIndex = function( index ) {
    	return this.$counters.filter( '[data-multistepform-step-counter="' + index + '"]' );
    };

    /**
     * Update the state of the next and previous buttons based on the current step index.
     * @return this
     */
    MultiStepForm.prototype._updateButtonState = function( index ) {
    	if ( index > 1 ) {
    		this._enableButton( this.$prevButton );
    	} else {
    		this._disableButton( this.$prevButton );
    	}

    	if ( index === this.$steps.length - 1 ) {
    		this._submitButton( this.$nextButton );
      } else if ( index === this.$steps.length ) {
        this.$prevButton.hide();
        this._startAgainButton( this.$nextButton );
    	} else {
    		this._unsubmitButton( this.$nextButton )._enableButton( this.$nextButton );
    	}

    	return this;
    };

    /**
     * Change a button's state to enabled.
     * @param  {jQuery} $button A button element.
     * @return this
     */
    MultiStepForm.prototype._enableButton = function( $button ) {
    	$button.removeAttr( 'disabled' );

    	return this;
    };

    /**
     * Change a button's state to disabled.
     * @param  {jQuery} $button A button element.
     * @return this
     */
    MultiStepForm.prototype._disableButton = function( $button ) {
    	$button.attr( 'disabled', 'disabled' );

    	return this;
    };

    MultiStepForm.prototype._startAgainButton = function( $button ) {
      $button.attr( 'data-original-text', $button.text() );

      $button.text( this.options.startAgainText );
      $button.attr( 'data-multistepform-navigation', 'reset' );
    };

    /**
     * Change a button's state to a submit button.
     * @param  {jQuery} $button A button element.
     * @return this
     */
    MultiStepForm.prototype._submitButton = function( $button ) {
      $button.attr( 'data-original-text', $button.text() );

      $button.text( this.options.submitText );
      // $button.attr( 'type', 'submit' );

      return this;
    };

    /**
     * Change a button's state to a standard button (from a submit button).
     * @param  {jQuery} $button A button element.
     * @return this
     */
    MultiStepForm.prototype._unsubmitButton = function( $button ) {
      $button.text( $button.attr( 'data-original-text' ) );
      $button.removeAttr( 'data-original-text' );

      return this;
    };

    // INIT, this == Set of jQuery elements plugin was called on

    var opt = arguments[0],
        args = Array.prototype.slice.call(arguments, 1),
        len = this.length,
        i = 0,
        ret,

        // Plugin default options
        defaults = {
        	/**
        	 * If true, the height will animate between step changes.
        	 * @type {Boolean}
        	 */
          adaptiveHeight: true,

          /**
           * The text that will be placed on the submit button
           * when you get to the end of the form.
           * @type {String}
           */
          submitText: 'Submit',

          /**
           * The text shown on the button
           * once the user has reached the end of the form.
           * @type {String}
           */
          startAgainText: 'Make another request',
        },

        // Create full options set
        // In order of importance (from least to most), use:
        //    plugin defaults, user overriden defaults, user passed options
        options = $.extend( true, {}, defaults, $.fn.multistepform.defaults, opt );

    // Store the instance to each element so that users can call methods on the prototype later
    for ( ; i < len ; i++) {
      if ( typeof opt == "object" || typeof opt == "undefined" ) {

        // If passed an object, representing plugin options, create a new MultiStepForm instance
        window._multistepformInstance = new MultiStepForm( this, options );
        this[i].multistepform = window._multistepformInstance;

      } else {

        // Otherwise this is a method call on the previous instance
        ret = this[i].multistepform[opt].apply( this[i].multistepform, args );

        // If the method call returns something, return that
        if ( typeof ret !== "undefined" ) {
          return ret;
        }

      }
    }

    // Return this for chaining
    return this;

  } );

  $(document).ready( function() {

    // Init on data attributes
    /*if ( $('[data-multistepform]').length ) {
      $('[data-multistepform]').each( function() {
        $(this).multistepform();
      } );
    }*/

  } );

} )( jQuery, window, document );
