// No wrapper to define this at the global window scope and get a couple of 
// cool enumeration code benefits. @see [enum]

/**
 * Respond to validation events from the multistep form.
 * @param  {Event} event  The event data.
 * @param  {Object} data  Data passed along with the event including:
 *                        	id {String} 		The idenitfying string of the form, from it's data-multistepform attribute.
 *                        	index {Number} 	The index number of the step being validated.
 *                        	inputs {jQuery} A jQuery collection of the input and textarea elements within the step being validated.
 * @return {Boolean} 			False if the validation found errors.
 */
function onValidateConsultationForm( event, data ) {
	
	if ( 'consultation' !== data.id ) {
		// Don't prevent default as something else might need to handle this
		return;
	}

	// [enum] Get a dynamic function name and call if it exists
	var functionName = 'validateConsultationStep' + data.index,
			fn = window[ functionName ],
			errors = null;

	if ( typeof fn === 'function' ) {
		errors = fn( data );
	}

	if ( null !== errors && errors.length > 0 ) {
		event.preventDefault();
		return false;
	}

	return true;
}

/**
 * Create an inline error message to display to the user.
 * The error message will be inserted after the element passed.
 * @param  {jQuery} $after  The element to insert the error message after.
 * @param  {string} message The message to show.
 */
function showInlineError( $after, message ) {
  var $msg_container = $after.siblings('.js-form-message'),
      msg_container_html = '<p class="form__message form__message--error js-form-message js-form-message--error">' + message + '</p>';

  if ( $msg_container.length > 0 ) {

    $msg_container.html( message );

  } else {

    $msg_container = $( msg_container_html );
    $msg_container.insertAfter( $after );

  }

  // It may be hidden so make sure it is not
  $msg_container.show();
}

/**
 * Validate step 1 of the form.
 * @param  {Object} data Passed data from the event, see data parameter on onValidateConsultationForm()
 * @return {Mixed}       An array of fields which have caused errors or null if there were none.
 */
function validateConsultationStep1( data ) {
	var errors = [],
			$fullname = data.inputs.filter('#fullname'),
			$company = data.inputs.filter('#company');
			$email = data.inputs.filter('#email');
      $email_re = /^[A-Z0-9._%+-]+@(?:[A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
			$telephone = data.inputs.filter('#telephone');
      $stripped_telephone = $telephone.val().replace(/[^0-9+]/g, '');

	if ( $fullname.is('[required]') && $fullname.val() === '' ) {
    $fullname.addClass('form__input--has-error');
    showInlineError( $fullname, 'Enter your full name' );

    errors.push( $fullname );
  } else if ( $fullname.val().length <= 1 ) {
    $fullname.addClass('form__input--has-error');
    showInlineError( $fullname, 'Name should be at least 2 characters' );
    
    errors.push( $fullname );
  }

  if ( $company.is('[required]') && $company.val() === '' ) {
    $company.addClass('form__input--has-error');
    showInlineError( $company, 'Enter your company name' );

    errors.push( $company );
  }

	if ( $email.is('[required]') && $email.val() === '' ) {
    $email.addClass('form__input--has-error');
    showInlineError( $email, 'Enter your email address' );

    errors.push( $email );
  } else if ( $email.val() !== '' && !$email_re.test( $email.val() ) ) {
    $email.addClass('form__input--has-error');
    showInlineError( $email, 'Enter a valid email address' );
    
    errors.push( $email );
  }

	if ( $telephone.is('[required]') && $telephone.val() === '' ) {
    $telephone.addClass('form__input--has-error');
    showInlineError( $telephone, 'Enter your telephone number' );

    errors.push( $telephone );
  } else if ( $telephone.val() !== '' && $stripped_telephone.length < 9 ) {
    $telephone.addClass('form__input--has-error');
    showInlineError( $telephone, 'Enter a valid telephone number' );
    
    errors.push( $telephone );
  }


	return errors.length > 0 ? errors : null;
}

/**
 * Validate step 2 of the form.
 * @param  {Object} data Passed data from the event, see data parameter on onValidateConsultationForm()
 * @return {Mixed}       An array of fields which have caused errors or null if there were none.
 */
function validateConsultationStep2( data ) {
	var errors = [],
			$company_sector = data.inputs.filter('#company_sector'),
			$company_size = data.inputs.filter('#company_size'),
			$services_wrapper = $('#services'),
			$services = data.inputs.filter('[name="service[]"]'),
			$services_checked = $services.filter(':checked'),
			$area_size = data.inputs.filter('#area_size'),
			$area_type = data.inputs.filter('#area_type'),
			$consent_wrapper = $('#consent_wrapper'),
			$consent = data.inputs.filter('#consent');


  if ( $company_sector.is('[required]') && $company_sector.val() === '' ) {
    $company_sector.addClass('form__input--has-error');
    showInlineError( $company_sector, 'Enter your company sector' );

    errors.push( $company_sector );
  }

  if ( $company_size.is('[required]') && $company_size.val() === '' ) {
    $company_size.addClass('form__input--has-error');
    showInlineError( $company_size, 'Enter your company size' );

    errors.push( $company_size );
  }

  // Something about services and checkboxes
  if ( 0 === $services_checked.length ) {
  	showInlineError( $services_wrapper, 'Choose at least one service' );

    errors.push( $services );	
  }


  if ( $area_size.is('[required]') && $area_size.val() === '' ) {
    $area_size.addClass('form__input--has-error');
    showInlineError( $area_size, 'Enter the size of the area' );

    errors.push( $area_size );
  }
  
  if ( $consent.prop("checked") != true ) {
    $consent.addClass('form__input--has-error');
    showInlineError( $consent_wrapper, 'You must agree' );

    errors.push( $consent );
  }

  if ( $area_type.is('[required]') && $area_type.val() === '' ) {
    $area_type.addClass('form__input--has-error');
    showInlineError( $area_type, 'Enter the type of the area' );

    errors.push( $area_type );
  }

	return errors.length > 0 ? errors : null;
}

$('[name="service[]"]').on( 'change', function() {
	if ( !$(this).prop('checked') ) {
		return;
	}

	var $error = $(this).closest('#services').next( '.js-form-message' );

	if ( $error.length ) {
		$error.slideUp( 250 );
	}
} );

/**
 * Validate step 3 of the form.
 * @param  {Object} data Passed data from the event, see data parameter on onValidateConsultationForm()
 * @return {Mixed}       An array of fields which have caused errors or null if there were none.
 */
// function validateConsultationStep3( data ) {
// 	console.log( 'Validate step 3', data.inputs );

// 	var errors = [],
// 			$date = data.inputs.filter('#date'),
// 			$time = data.inputs.filter('#time');

//   if ( $date.is('[required]') && $date.val() === '' ) {
//     $date.addClass('form__input--has-error');
//     showInlineError( $date, 'Enter a date' );

//     errors.push( $date );
//   }

//   if ( $time.is('[required]') && $time.val() === '' ) {
//     $time.addClass('form__input--has-error');
//     showInlineError( $time, 'Enter a time' );

//     errors.push( $time );
//   }

// 	return errors.length > 0 ? errors : null;
// }

/**
 * Validate step 4 of the form.
 * @param  {Object} data Passed data from the event, see data parameter on onValidateConsultationForm()
 * @return {Mixed}       An array of fields which have caused errors or null if there were none.
 */
function validateConsultationStep3( data ) {
	// Nothing to validate - completion step
	return null;
}

// Bind the validation event
$('[data-multistepform="consultation"]').on( 'multistepform.validateStep', onValidateConsultationForm );


/**
 * Respond to submit events on the multistep form.
 * @param  {Event} event  The event data.
 * @param  {Object} data  Data passed along with the event including:
 *                        	id {String} 		The idenitfying string of the form, from it's data-multistepform attribute.
 *                        	index {Number} 	The index number of the step being validated.
 *                        	inputs {jQuery} A jQuery collection of the input and textarea elements within the step being validated.
 */
function onSubmitConsultationForm( event, data ) {
	var postData = {
				action: 'submit_consultation_form',
				fields: {}
			},
			ajaxURL = wplocal.ajaxURL,
			fields = {};
			services = [];

	// Gather fields
	data.wrapper.find( 'input, textarea, select' ).each( function() {

		if ( 'service[]' === $(this).attr('name') ) {
			if ( $(this).prop('checked') ) {
				services.push( $(this).val() );
			}
		} else {
			fields[ $(this).attr('id') ] = $(this).val();
		}

	} );

	fields.services = services;
	postData.fields = fields;

	$.ajax( {
		url: ajaxURL,
		method: 'POST',
		data: postData,
		dataType: 'json',
		success: function( data, textStatus, jqXHR ) {
			// Data should just be a return - no need to handle it
			console.log( data, textStatus, jqXHR );
		},
		error: function( jqXHR, textStatus, errorThrown ) {
			console.log( 'AJAX error:', jqXHR, textStatus, errorThrown );

			// Trigger some kind of error state and stop the form moving to the final step
			event.preventDefault();
			return false;
		}
	} );
}

// Bind the submit event
$('[data-multistepform="consultation"]').on( 'multistepform.submitForm', onSubmitConsultationForm );
