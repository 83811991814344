// Video controller

( function( $, window, document, undefined ) {
	
	var VideoController = function( $trigger ) {
		this.$trigger = $trigger;

		this.$container = this._getContainer();
		this.$video = this._getVideo();

		// Only run set up if we have a target
		if ( false !== this.$video ) {
			this._bindEvents();
		}
	};

	VideoController.prototype._bindEvents = function() {
		var _ = this;

		_.$trigger.on( 'click', function( event ) {
			_.play();

			event.preventDefault();
			return false;
		} );
	};

	VideoController.prototype._getContainer = function() {
		var $container = this.$trigger.closest( this.$trigger.data('videoContainer') );

		if ( $container.length ) {
			return $container;
		} else {
			// Could not find a target container
			return false;
		}
	};

	VideoController.prototype._getVideo = function() {
		var embedURL = this.$trigger.data('videoUrl'),
				$embed = $('<iframe></iframe>');

		$embed.attr( {
			class: 'hero__video',
			id: 'hero-video',
			src: embedURL,
			width: this.$container.outerWidth(),
			height: this.$container.outerWidth(),
			frameborder: 0,
			allowfullscreen: 'true'
		} );
		return $embed;
	};

	VideoController.prototype.play = function() {
		this._prepareToPlay();
		this.$container.append( this.$video );
	
		return this;
	};

	VideoController.prototype._prepareToPlay = function() {
		var deferred = $.Deferred(),
				aniamtionDuration = 250;

		if ( $('html').hasClass('ie9') ) {
			this.getElementsToRemove().css( {
				visibility: 'hidden',
			} );
		} else {
			// Fade out to visibility:hidden, instead of display:none;
			this.getElementsToRemove().addClass( 'u-fadeOut' );
		}

		setTimeout(function() {
			deferred.resolve();
		}, aniamtionDuration);

		return deferred;
	};

	VideoController.prototype.getElementsToRemove = function() {
		return this.$container.children();
	};

	// Init on data attributes
	var $dataVideos = $('[data-video-url]'),
			activeVideos = [];

	if ( $dataVideos.length ) {
		$dataVideos.each( function() {
			
			if ( 'button' === this.tagName.toLowerCase() ) {
				activeVideos.push( new VideoController( $(this) ) );
			}

		} );
	}

	// Expose as global object
	window.activeVideos = activeVideos;
	window.VideoController = VideoController;

} )( jQuery, window, document );
