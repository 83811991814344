// Utilities

function _n( singular, plural, value ) {
	if ( 1 === value ) {
		return singular;
	} else {
		return plural;
	}
}

$(document).ready(function() {
   
    $("#application_submit").click( function(event) {
       
        var form = $(this).closest('form'),
            antiSpam = form.find('#url');

        if ( antiSpam.val() !== '' ) {
            antiSpam.addClass('form__input--has-error');
            show_inline_result( antiSpam, 'Leave the spam prevention field blank' );

            event.preventDefault();
            return false;
        }        

        var name = form.find('#application_name'),

            email = form.find('#application_email'),
            email_re = /^[A-Z0-9._%+-]+@(?:[A-Z0-9-]+\.)+[A-Z]{2,4}$/i,

            phone = form.find('#application_phone'),
            stripped_phone = phone.val().replace(/[^0-9+]/g, ''),

            cv = form.find('#application_cv'),
            cvIsUploaded = cv.get(0).files.length > 0,

            proceed = true;


        // START VALIDATION

        if( name.is('[required]') && name.val() === '' ) {
            name.addClass('form__input--has-error');
            show_inline_result( name, 'Enter your name' );

            proceed = false;
        }
        if ( name.val() !== '' && name.val().length <= 1 ) {
            name.addClass('form__input--has-error');
            show_inline_result( name, 'Name should be at least 2 characters' );
            
            proceed = false;
        }


        if( email.is('[required]') && email.val() === '' ) {
            email.addClass('form__input--has-error');
            show_inline_result( email, 'Enter your email address' );
            
            proceed = false;
        }
        if ( email.val() !== '' && !email_re.test( email.val() ) ) {
            email.addClass('form__input--has-error');
            show_inline_result( email, 'Enter a valid email address' );

            proceed = false;
        }


        if( phone.is('[required]') && phone.val() === '' ) {
            phone.addClass('form__input--has-error');
            show_inline_result( phone, 'Enter your phone number' );

            proceed = false;
        }
        if ( phone.val() !== '' && stripped_phone.length < 9 ) {
            phone.addClass('form__input--has-error');
            show_inline_result( phone, 'Enter a valid phone number' );

            proceed = false;
        }


        if( cv.is('[required]') && !cvIsUploaded ) {
            cv.addClass('form__input--has-error');
            show_inline_result( cv.next('label'), 'You must upload a CV file' );

            proceed = false;
        }

        // END VALIDATION


        if( !proceed ) {
            // Transfer focus from submit button to first error field
            var error_fields = $('.js-form-message--error').filter(':visible');

            if ( error_fields.length ) {
                var $first_error = error_fields.eq(0),
                    $first_error_input = $first_error.siblings('input,textarea,select'),
                    offset = 0;

                if ( $first_error_input.length > 0 ) {
                    offset = $first_error_input.offset().top;
                } else {
                    offset = $first_error.offset().top;
                }

                smooth_scroll( offset - 250, 250 );
                setTimeout(function() {
                    if ( $first_error_input.length > 0 ) {
                        $first_error_input.focus();
                    }
                }, 250);
            }

            event.preventDefault();
            return false;
        }
    });

    //reset previously set border color after entering text
    $('input, textarea, select').on( 'change', function() {
        $(this).removeClass('form__input--has-error');

        if ( $(this).siblings('.js-form-message--error').length ) {
            $(this).siblings('.js-form-message--error').slideUp(250);
        }
    });

    function smooth_scroll( y, speed ) {
        speed = speed || 250;

        $('html, body').stop().animate({
            scrollTop: y
        }, speed);
    }

    function show_inline_result( field, message, status ) {
        status = 'error' || status;

        var msg_container = field.siblings('.js-form-message'),
            msg_container_html = '<p class="form__message form__message--'+status+' js-form-message js-form-message--'+status+'" style="display:none;">' + message + '</p>';

        if ( msg_container.length > 0 ) {

            if ( msg_container.is(':visible') ) {
                msg_container.html( message ).hide().fadeIn(250);
            } else {
                msg_container.html( message ).hide().slideDown(250);
            }

        } else {

            msg_container = $( msg_container_html );
            msg_container.insertAfter( field ).slideDown(250);

        }

    }
});

( function( $, window, document, undefined ) {
       
    $(document).on( 'click touchstart', '#team_submit-fl', function(event) {
       
        var form = $(this).closest('form'),
            antiSpam = form.find('#team_url-fl');

        if ( antiSpam.val() !== '' ) {
            antiSpam.addClass('form__input--has-error');
            show_inline_result( antiSpam, 'Leave the spam prevention field blank' );

            event.preventDefault();
            return false;
        }        

        var name = form.find('#team_name-fl'),

            email = form.find('#team_email-fl'),
            email_re = /^[A-Z0-9._%+-]+@(?:[A-Z0-9-]+\.)+[A-Z]{2,4}$/i,

            phone = form.find('#team_phone-fl'),
            stripped_phone = phone.val().replace(/[^0-9+]/g, ''),

            cv = form.find('#team_cv-fl'),
            cvIsUploaded = cv.get(0).files.length > 0,

            proceed = true;


        // START VALIDATION

        if( name.is('[required]') && name.val() === '' ) {
            name.addClass('form__input--has-error');
            show_inline_result( name, 'Enter your name' );

            proceed = false;
        }
        if ( name.val() !== '' && name.val().length <= 1 ) {
            name.addClass('form__input--has-error');
            show_inline_result( name, 'Name should be at least 2 characters' );
            
            proceed = false;
        }



        if( email.is('[required]') && email.val() === '' ) {
            email.addClass('form__input--has-error');
            show_inline_result( email, 'Enter your email address' );
            
            proceed = false;
        }
        if ( email.val() !== '' && !email_re.test( email.val() ) ) {
            email.addClass('form__input--has-error');
            show_inline_result( email, 'Enter a valid email address' );

            proceed = false;
        }


        if( phone.is('[required]') && phone.val() === '' ) {
            phone.addClass('form__input--has-error');
            show_inline_result( phone, 'Enter your phone number' );

            proceed = false;
        }
        if ( phone.val() !== '' && stripped_phone.length < 9 ) {
            phone.addClass('form__input--has-error');
            show_inline_result( phone, 'Enter a valid phone number' );

            proceed = false;
        }


        if( cv.is('[required]') && !cvIsUploaded ) {
            cv.addClass('form__input--has-error');
            show_inline_result( cv.next('label'), 'You must upload a CV file' );

            proceed = false;
        }

        // END VALIDATION


        if( !proceed ) {
            // Transfer focus from submit button to first error field
            var error_fields = $('.js-form-message--error').filter(':visible');

            if ( error_fields.length ) {
                var $first_error = error_fields.eq(0),
                    $first_error_input = $first_error.siblings('input,textarea,select'),
                    offset = 0;

                if ( $first_error_input.length > 0 ) {
                    offset = $first_error_input.offset().top;
                } else {
                    offset = $first_error.offset().top;
                }

                smooth_scroll( offset - 250, 250 );
                setTimeout(function() {
                    if ( $first_error_input.length > 0 ) {
                        $first_error_input.focus();
                    }
                }, 250);
            }

            event.preventDefault();
            return false;
        }
    });

    //reset previously set border color after entering text
    $(document).on( 'change', 'input, textarea, select', function() {
        $(this).removeClass('form__input--has-error');

        if ( $(this).siblings('.js-form-message--error').length ) {
            $(this).siblings('.js-form-message--error').slideUp(250);
        }
    });

    function show_inline_result( field, message, status ) {
        status = 'error' || status;

        var msg_container = field.siblings('.js-form-message'),
            msg_container_html = '<p class="form__message form__message--'+status+' js-form-message js-form-message--'+status+'" style="display:none;">' + message + '</p>';

        if ( msg_container.length > 0 ) {

            if ( msg_container.is(':visible') ) {
                msg_container.html( message ).hide().fadeIn(250);
            } else {
                msg_container.html( message ).hide().slideDown(250);
            }

        } else {

            msg_container = $( msg_container_html );
            msg_container.insertAfter( field ).slideDown(250);

        }

    }

} )( jQuery, window, document );

$(document).ready(function() {
   
    $(document).on( 'click', "#callback_submit-featherlight", function(event) {
       
        var form = $(this).closest('form'),
            $featherlight = $(this).closest('.featherlight'),
            suffix = ( $featherlight.length ? '-featherlight' : '' ),
            antiSpam = form.find('#url' + suffix);

        if ( antiSpam.val() !== '' ) {
            antiSpam.addClass('form__input--has-error');
            show_inline_result( antiSpam, 'Leave the spam prevention field blank' );

            event.preventDefault();
            return false;
        }        

        var name = form.find('#callback_name' + suffix),

            email = form.find('#callback_email' + suffix),
            email_re = /^[A-Z0-9._%+-]+@(?:[A-Z0-9-]+\.)+[A-Z]{2,4}$/i,

            phone = form.find('#callback_phone' + suffix),
            stripped_phone = phone.val().replace(/[^0-9+]/g, ''),

            enquiry = form.find('#callback_enquiry' + suffix),

            proceed = true;


        // START VALIDATION

        if( name.is('[required]') && name.val() === '' ) {
            name.addClass('form__input--has-error');
            show_inline_result( name, 'Enter your first name' );

            proceed = false;
        }
        if ( name.val() !== '' && name.val().length <= 1 ) {
            name.addClass('form__input--has-error');
            show_inline_result( name, 'First name should be at least 2 characters' );
            
            proceed = false;
        }


        if( email.is('[required]') && email.val() === '' ) {
            email.addClass('form__input--has-error');
            show_inline_result( email, 'Enter your email address' );
            
            proceed = false;
        }
        if ( email.val() !== '' && !email_re.test( email.val() ) ) {
            email.addClass('form__input--has-error');
            show_inline_result( email, 'Enter a valid email address' );

            proceed = false;
        }


        if( phone.is('[required]') && phone.val() === '' ) {
            phone.addClass('form__input--has-error');
            show_inline_result( phone, 'Enter your phone number' );

            proceed = false;
        }
        if ( phone.val() !== '' && stripped_phone.length < 9 ) {
            phone.addClass('form__input--has-error');
            show_inline_result( phone, 'Enter a valid phone number' );

            proceed = false;
        }


        if( enquiry.is('[required]') && enquiry.val() === '' ) {
            enquiry.addClass('form__input--has-error');
            show_inline_result( enquiry, 'Enter an enquiry' );
            
            proceed = false;
        }
        if ( enquiry.val() !== '' && enquiry.val().length <= 5 ) {
            enquiry.addClass('form__input--has-error');
            show_inline_result( enquiry, 'Enquiry should be at least 5 characters' );

            proceed = false;
        }

        // END VALIDATION


        if( !proceed ) {
            // Transfer focus from submit button to first error field
            var error_fields = $('.js-form-message--error').filter(':visible');

            if ( error_fields.length ) {
                var $first_error = error_fields.eq(0),
                    $first_error_input = $first_error.siblings('input,textarea,select'),
                    offset = 0;

                if ( $first_error_input.length > 0 ) {
                    offset = $first_error_input.offset().top;
                } else {
                    offset = $first_error.offset().top;
                }

                smooth_scroll( offset - 250, 250 );
                setTimeout(function() {
                    if ( $first_error_input.length > 0 ) {
                        $first_error_input.focus();
                    }
                }, 250);
            }

            event.preventDefault();
            return false;
        }
    });

    //reset previously set border color after entering text
    $('input, textarea, select').on( 'change', function() {
        $(this).removeClass('form__input--has-error');

        if ( $(this).siblings('.js-form-message--error').length ) {
            $(this).siblings('.js-form-message--error').slideUp(250);
        }
    });

    function smooth_scroll( y, speed ) {
        speed = speed || 250;

        $('html, body').stop().animate({
            scrollTop: y
        }, speed);
    }

    function show_inline_result( field, message, status ) {
        status = 'error' || status;

        var msg_container = field.siblings('.js-form-message'),
            msg_container_html = '<p class="form__message form__message--'+status+' js-form-message js-form-message--'+status+'" style="display:none;">' + message + '</p>';

        if ( msg_container.length > 0 ) {

            if ( msg_container.is(':visible') ) {
                msg_container.html( message ).hide().fadeIn(250);
            } else {
                msg_container.html( message ).hide().slideDown(250);
            }

        } else {

            msg_container = $( msg_container_html );
            msg_container.insertAfter( field ).slideDown(250);

        }

    }
});

// No wrapper to define this at the global window scope and get a couple of 
// cool enumeration code benefits. @see [enum]

/**
 * Respond to validation events from the multistep form.
 * @param  {Event} event  The event data.
 * @param  {Object} data  Data passed along with the event including:
 *                        	id {String} 		The idenitfying string of the form, from it's data-multistepform attribute.
 *                        	index {Number} 	The index number of the step being validated.
 *                        	inputs {jQuery} A jQuery collection of the input and textarea elements within the step being validated.
 * @return {Boolean} 			False if the validation found errors.
 */
function onValidateConsultationForm( event, data ) {
	
	if ( 'consultation' !== data.id ) {
		// Don't prevent default as something else might need to handle this
		return;
	}

	// [enum] Get a dynamic function name and call if it exists
	var functionName = 'validateConsultationStep' + data.index,
			fn = window[ functionName ],
			errors = null;

	if ( typeof fn === 'function' ) {
		errors = fn( data );
	}

	if ( null !== errors && errors.length > 0 ) {
		event.preventDefault();
		return false;
	}

	return true;
}

/**
 * Create an inline error message to display to the user.
 * The error message will be inserted after the element passed.
 * @param  {jQuery} $after  The element to insert the error message after.
 * @param  {string} message The message to show.
 */
function showInlineError( $after, message ) {
  var $msg_container = $after.siblings('.js-form-message'),
      msg_container_html = '<p class="form__message form__message--error js-form-message js-form-message--error">' + message + '</p>';

  if ( $msg_container.length > 0 ) {

    $msg_container.html( message );

  } else {

    $msg_container = $( msg_container_html );
    $msg_container.insertAfter( $after );

  }

  // It may be hidden so make sure it is not
  $msg_container.show();
}

/**
 * Validate step 1 of the form.
 * @param  {Object} data Passed data from the event, see data parameter on onValidateConsultationForm()
 * @return {Mixed}       An array of fields which have caused errors or null if there were none.
 */
function validateConsultationStep1( data ) {
	var errors = [],
			$fullname = data.inputs.filter('#fullname'),
			$company = data.inputs.filter('#company');
			$email = data.inputs.filter('#email');
      $email_re = /^[A-Z0-9._%+-]+@(?:[A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
			$telephone = data.inputs.filter('#telephone');
      $stripped_telephone = $telephone.val().replace(/[^0-9+]/g, '');

	if ( $fullname.is('[required]') && $fullname.val() === '' ) {
    $fullname.addClass('form__input--has-error');
    showInlineError( $fullname, 'Enter your full name' );

    errors.push( $fullname );
  } else if ( $fullname.val().length <= 1 ) {
    $fullname.addClass('form__input--has-error');
    showInlineError( $fullname, 'Name should be at least 2 characters' );
    
    errors.push( $fullname );
  }

  if ( $company.is('[required]') && $company.val() === '' ) {
    $company.addClass('form__input--has-error');
    showInlineError( $company, 'Enter your company name' );

    errors.push( $company );
  }

	if ( $email.is('[required]') && $email.val() === '' ) {
    $email.addClass('form__input--has-error');
    showInlineError( $email, 'Enter your email address' );

    errors.push( $email );
  } else if ( $email.val() !== '' && !$email_re.test( $email.val() ) ) {
    $email.addClass('form__input--has-error');
    showInlineError( $email, 'Enter a valid email address' );
    
    errors.push( $email );
  }

	if ( $telephone.is('[required]') && $telephone.val() === '' ) {
    $telephone.addClass('form__input--has-error');
    showInlineError( $telephone, 'Enter your telephone number' );

    errors.push( $telephone );
  } else if ( $telephone.val() !== '' && $stripped_telephone.length < 9 ) {
    $telephone.addClass('form__input--has-error');
    showInlineError( $telephone, 'Enter a valid telephone number' );
    
    errors.push( $telephone );
  }

	return errors.length > 0 ? errors : null;
}

/**
 * Validate step 2 of the form.
 * @param  {Object} data Passed data from the event, see data parameter on onValidateConsultationForm()
 * @return {Mixed}       An array of fields which have caused errors or null if there were none.
 */
function validateConsultationStep2( data ) {
	var errors = [],
			$company_sector = data.inputs.filter('#company_sector'),
			$company_size = data.inputs.filter('#company_size'),
			$services_wrapper = $('#services'),
			$services = data.inputs.filter('[name="service[]"]'),
			$services_checked = $services.filter(':checked'),
			$area_size = data.inputs.filter('#area_size'),
			$area_type = data.inputs.filter('#area_type');

  if ( $company_sector.is('[required]') && $company_sector.val() === '' ) {
    $company_sector.addClass('form__input--has-error');
    showInlineError( $company_sector, 'Enter your company sector' );

    errors.push( $company_sector );
  }

  if ( $company_size.is('[required]') && $company_size.val() === '' ) {
    $company_size.addClass('form__input--has-error');
    showInlineError( $company_size, 'Enter your company size' );

    errors.push( $company_size );
  }

  // Something about services and checkboxes
  if ( 0 === $services_checked.length ) {
  	showInlineError( $services_wrapper, 'Choose at least one service' );

    errors.push( $services );	
  }


  if ( $area_size.is('[required]') && $area_size.val() === '' ) {
    $area_size.addClass('form__input--has-error');
    showInlineError( $area_size, 'Enter the size of the area' );

    errors.push( $area_size );
  }

  if ( $area_type.is('[required]') && $area_type.val() === '' ) {
    $area_type.addClass('form__input--has-error');
    showInlineError( $area_type, 'Enter the type of the area' );

    errors.push( $area_type );
  }

	return errors.length > 0 ? errors : null;
}

$('[name="service[]"]').on( 'change', function() {
	if ( !$(this).prop('checked') ) {
		return;
	}

	var $error = $(this).closest('#services').next( '.js-form-message' );

	if ( $error.length ) {
		$error.slideUp( 250 );
	}
} );

/**
 * Validate step 3 of the form.
 * @param  {Object} data Passed data from the event, see data parameter on onValidateConsultationForm()
 * @return {Mixed}       An array of fields which have caused errors or null if there were none.
 */
// function validateConsultationStep3( data ) {
// 	console.log( 'Validate step 3', data.inputs );

// 	var errors = [],
// 			$date = data.inputs.filter('#date'),
// 			$time = data.inputs.filter('#time');

//   if ( $date.is('[required]') && $date.val() === '' ) {
//     $date.addClass('form__input--has-error');
//     showInlineError( $date, 'Enter a date' );

//     errors.push( $date );
//   }

//   if ( $time.is('[required]') && $time.val() === '' ) {
//     $time.addClass('form__input--has-error');
//     showInlineError( $time, 'Enter a time' );

//     errors.push( $time );
//   }

// 	return errors.length > 0 ? errors : null;
// }

/**
 * Validate step 4 of the form.
 * @param  {Object} data Passed data from the event, see data parameter on onValidateConsultationForm()
 * @return {Mixed}       An array of fields which have caused errors or null if there were none.
 */
function validateConsultationStep3( data ) {
	// Nothing to validate - completion step
	return null;
}

// Bind the validation event
$('[data-multistepform="consultation"]').on( 'multistepform.validateStep', onValidateConsultationForm );


/**
 * Respond to submit events on the multistep form.
 * @param  {Event} event  The event data.
 * @param  {Object} data  Data passed along with the event including:
 *                        	id {String} 		The idenitfying string of the form, from it's data-multistepform attribute.
 *                        	index {Number} 	The index number of the step being validated.
 *                        	inputs {jQuery} A jQuery collection of the input and textarea elements within the step being validated.
 */
function onSubmitConsultationForm( event, data ) {
	var postData = {
				action: 'submit_consultation_form',
				fields: {}
			},
			ajaxURL = wplocal.ajaxURL,
			fields = {};
			services = [];

	// Gather fields
	data.wrapper.find( 'input, textarea, select' ).each( function() {

		if ( 'service[]' === $(this).attr('name') ) {
			if ( $(this).prop('checked') ) {
				services.push( $(this).val() );
			}
		} else {
			fields[ $(this).attr('id') ] = $(this).val();
		}

	} );

	fields.services = services;
	postData.fields = fields;

	$.ajax( {
		url: ajaxURL,
		method: 'POST',
		data: postData,
		dataType: 'json',
		success: function( data, textStatus, jqXHR ) {
			// Data should just be a return - no need to handle it
			console.log( data, textStatus, jqXHR );
		},
		error: function( jqXHR, textStatus, errorThrown ) {
			console.log( 'AJAX error:', jqXHR, textStatus, errorThrown );

			// Trigger some kind of error state and stop the form moving to the final step
			event.preventDefault();
			return false;
		}
	} );
}

// Bind the submit event
$('[data-multistepform="consultation"]').on( 'multistepform.submitForm', onSubmitConsultationForm );

$(document).ready(function() {
    
    $("#enquiry_submit").click( function(event) {
       
        var form = $(this).closest('form'),
            antiSpam = form.find('#url');

        if ( antiSpam.val() !== '' ) {
            antiSpam.addClass('form__input--has-error');
            show_inline_result( antiSpam, 'Leave the spam prevention field blank' );

            event.preventDefault();
            return false;
        }        

        var name = form.find('#enquiry_name'),

            company = form.find('#enquiry_company'),

            email = form.find('#enquiry_email'),
            email_re = /^[A-Z0-9._%+-]+@(?:[A-Z0-9-]+\.)+[A-Z]{2,4}$/i,

            phone = form.find('#enquiry_phone'),
            stripped_phone = phone.val().replace(/[^0-9+]/g, ''),

            enquiry = form.find('#enquiry_enquiry'),

            proceed = true;


        // START VALIDATION

        if( name.is('[required]') && name.val() === '' ) {
            name.addClass('form__input--has-error');
            show_inline_result( name, 'Enter your name' );

            proceed = false;
        }
        if ( name.val() !== '' && name.val().length <= 1 ) {
            name.addClass('form__input--has-error');
            show_inline_result( name, 'Name should be at least 2 characters' );
            
            proceed = false;
        }

        if ( company.val() !== '' && company.val().length <= 1 ) {
            company.addClass('form__input--has-error');
            show_inline_result( company, 'Company name should be at least 2 characters' );
            
            proceed = false;
        }


        if( email.is('[required]') && email.val() === '' ) {
            email.addClass('form__input--has-error');
            show_inline_result( email, 'Enter your email address' );
            
            proceed = false;
        }
        if ( email.val() !== '' && !email_re.test( email.val() ) ) {
            email.addClass('form__input--has-error');
            show_inline_result( email, 'Enter a valid email address' );

            proceed = false;
        }


        if( phone.is('[required]') && phone.val() === '' ) {
            phone.addClass('form__input--has-error');
            show_inline_result( phone, 'Enter your phone number' );

            proceed = false;
        }
        if ( phone.val() !== '' && stripped_phone.length < 9 ) {
            phone.addClass('form__input--has-error');
            show_inline_result( phone, 'Enter a valid phone number' );

            proceed = false;
        }


        if ( enquiry.val() !== '' && enquiry.val().length <= 5 ) {
            enquiry.addClass('form__input--has-error');
            show_inline_result( enquiry, 'Enquiry should be at least 5 characters' );

            proceed = false;
        }

        // END VALIDATION


        if( !proceed ) {
            // Transfer focus from submit button to first error field
            var error_fields = $('.js-form-message--error').filter(':visible');

            if ( error_fields.length ) {
                var $first_error = error_fields.eq(0),
                    $first_error_input = $first_error.siblings('input,textarea,select'),
                    offset = 0;

                if ( $first_error_input.length > 0 ) {
                    offset = $first_error_input.offset().top;
                } else {
                    offset = $first_error.offset().top;
                }

                smooth_scroll( offset - 250, 250 );
                setTimeout(function() {
                    if ( $first_error_input.length > 0 ) {
                        $first_error_input.focus();
                    }
                }, 250);
            }

            event.preventDefault();
            return false;
        }
    });

    //reset previously set border color after entering text
    $('input, textarea, select').on( 'change', function() {
        $(this).removeClass('form__input--has-error');

        if ( $(this).siblings('.js-form-message--error').length ) {
            $(this).siblings('.js-form-message--error').slideUp(250);
        }
    });

    function smooth_scroll( y, speed ) {
        speed = speed || 250;

        $('html, body').stop().animate({
            scrollTop: y
        }, speed);
    }

    function show_inline_result( field, message, status ) {
        status = 'error' || status;

        var msg_container = field.siblings('.js-form-message'),
            msg_container_html = '<p class="form__message form__message--'+status+' js-form-message js-form-message--'+status+'" style="display:none;">' + message + '</p>';

        if ( msg_container.length > 0 ) {

            if ( msg_container.is(':visible') ) {
                msg_container.html( message ).hide().fadeIn(250);
            } else {
                msg_container.html( message ).hide().slideDown(250);
            }

        } else {

            msg_container = $( msg_container_html );
            msg_container.insertAfter( field ).slideDown(250);

        }

    }
});

// Init the EqualHeight plugin
( function( $, window, document, undefined ) {
	$( '.js-equalHeight-sidebar' ).equalHeight();
	$( '.js-equalHeight-processItem' ).equalHeight();
	$( '.js-equalHeight-teamContent' ).equalHeight();
	$( '.js-equalHeight-search' ).equalHeight();
} )( jQuery, window, document );

// Setup the Featherlight plugin
// This file is sectioned based on various functionality.
// If you don't need one of the parts get rid!

( function( $, window, document, undefined ) {
		
	/**
	 * Add a suffix to an element's attribute.
	 * @param {jQuery} $elem    The element to modify.
	 * @param {string} attrName The name of the attribute to modify.
	 * @param {string} suffix   The suffix to add.
	 */
	function addAttrSuffix( $elem, attrName, suffix ) {
		if ( $elem.attr( attrName ) ) {
			$elem.attr( attrName, $elem.attr( attrName ) + suffix );
		}
	}

	/**
	 * Clone a featherlight container so that all IDs, and for attributes, remain unique.
	 * Other attributes, aria- & data- for example could be referencing an ID but there are so many it would be near impossible to change them all.
	 */
	function cloneFeatherlight( $target, variant ) {
		if ( $target.length ) {
			$target = $target.clone();

			$target.find('[for]').each( function() {
				addAttrSuffix( $(this), 'for', '-featherlight' );
			} );

			$target.find('[id]').each( function() {
				addAttrSuffix( $(this), 'id', '-featherlight' );
			} );

			$.featherlight( $target, { variant: variant } );
		}
	}
	
	if ( typeof $.featherlight !== 'undefined' ) {

		// Expose as a property
		$.featherlight.cloneFeatherlight = cloneFeatherlight;
		
		/**
		 * Open a cloned version of a form in a Featherlight lightbox, rather than the actual form.
		 * This makes sure that IDs aren't repeated across the page, helping with targeting events, clicks on <label>'s etc.
		 * By default adds the '-featherlight' suffix to all 'for' and 'id' attributes.
		 */
		$('[data-featherlight-clone]').click( function( event ) {
			var variant = null;

			if ( $(this)[0].hasAttribute('data-featherlight-variant') ) {
				variant = $(this).attr('data-featherlight-variant');
			}

			cloneFeatherlight( $( $(this).attr('data-featherlight-clone') ), variant );

			event.preventDefault();
			return false;
		} );
	

// ~~~~~~~~~ STOP SCROLL ON THE BODY WHEN A FEATHERLIGHT CONTAINER IS OPEN ~~~~~~~~~ 

		var scroll_top = document.body.scrollTop,
				// Keep track of how many are open, for nested featherlights
				container_count_open = 0, 
				
				freezeScroll = function() {
					container_count_open++;

					if ( 1 == container_count_open ) {
						scroll_top = document.body.scrollTop;

						$('body').css( {
							position: 'fixed',
							top: -(scroll_top  - parseInt( $('html').css('marginTop') ) ) + 'px',
							overflowY: 'scroll',
						} );
					}
				},

				restoreScroll = function() {
					container_count_open--;

					if ( 0 === container_count_open ) {
						$('body').css( {
							'position': '',
							'top': '',
							'overflow-y': '',
						} );
						document.body.scrollTop = scroll_top;
					}
				},

				afterOpen = function( event ) {
					freezeScroll();

					var $videos = this.$instance.find( '[data-video-url]' ),
							_videoController;

					if ( $videos.length ) {
						$videos.each( function () {

							if ( 'button' === this.tagName.toLowerCase() ) {
								_videoController = new VideoController( $(this) );
								activeVideos.push( _videoController );

								_videoController.play();
							}

						} );
					}
				},
				beforeClose = function( event ) {
					restoreScroll();
				};

		$.featherlight.defaults.afterOpen = afterOpen;
		$.featherlight.defaults.beforeClose = beforeClose;
		$.featherlight.defaults.persist = false;

	}

} )( jQuery, window, document );

// Small jQuery library to init Google Maps

( function( $, window, document, undefined ) {
		
	// Constructor
	var GoogleMap = function( $mapElement, markers, initialZoom ) {

		// Set properties
		this.$mapElement = $mapElement;
		this.markers = markers;

		if ( undefined === initialZoom ) {
			this.initialZoom = 14;
		} else {
			this.initialZoom = initialZoom;
		}

		this.options = {
			zoom  		  : this.initialZoom,
			center		  : new google.maps.LatLng(0, 0),
			mapTypeId	  : google.maps.MapTypeId.ROADMAP,
			scrollwheel : false
		};

		// Kick off load
		this._init();
	};

	GoogleMap.prototype._init = function() {
		// Get a map object
		this.map = new google.maps.Map( this.$mapElement.get(0), this.options );

		// Create markers reference
		this.map.markers = [];

		for (var i = 0, len = this.markers.length; i < len; i++) {
			var _marker = this.markers[i];

			this.addMarker( _marker );
		}

		this.centerMap();

		// Trigger event on finish init
		this.$mapElement.trigger( 'googleMap-ready' );
	};

	GoogleMap.prototype.addMarker = function( marker ) {
		var latlng = new google.maps.LatLng( marker.lat, marker.lng ),
				mapMarker = new google.maps.Marker( {
					position	: latlng,
					map			  : this.map
				} );

		this.map.markers.push( mapMarker );

		// If the marker contains HTML then add it in an infowindow
		if ( marker.hasOwnProperty( 'content' ) && '' !== marker.content ) {
			this.addInfoWindow( mapMarker, marker.content );
		}
	};

	GoogleMap.prototype.addInfoWindow = function( mapMarker, content ) {
		var infoWindow = new google.maps.InfoWindow( {
					content: content
				} );

		// Add open event
		google.maps.event.addListener( mapMarker, 'click', function() {
			infoWindow.open( this.map, mapMarker );
		} );
	};

	GoogleMap.prototype.centerMap = function() {
		var bounds = new google.maps.LatLngBounds();

		for (var i = 0, len = this.map.markers.length; i < len; i++) {
			var _mapMarker = this.map.markers[i],
					latlng = new google.maps.LatLng( _mapMarker.position.lat(), _mapMarker.position.lng() );
			
			bounds.extend( latlng );
		}

		if( this.map.markers.length == 1 ) {
	    this.map.setCenter( bounds.getCenter() );
	    this.map.setZoom( this.initialZoom );
		} else {
			this.map.fitBounds( bounds );
		}
	};

	/**
	 * Get an array of marker objects with optional content - { lat, lng, [content] }.
	 * @param {jQuery} $mapElement The element the map will be created on, should contain marker elements.
	 * @return array
	 */
	function getMarkers( $mapElement ) {
		var $markers = $mapElement.find( '.js-googleMap__marker' ),
				markers = [];

		$markers.each( function( i, marker ) {
			var $marker = $(marker),
					markerObject = {};

			if ( $marker.attr( 'data-lat' ) && $marker.attr( 'data-lng' ) ) {
				
				markerObject.lat = $marker.attr( 'data-lat' );
				markerObject.lng = $marker.attr( 'data-lng' );
				
				if ( $marker.html() ) {
					markerObject.content = $marker.html();
				}

				markers.push( markerObject );

			}
		} );

		return markers;
	}

	var $mapElements = $('.js-googleMap');

	// Once init'd hide any elements that need to
	$mapElements.on( 'googleMap-ready', function() {
		$(this).siblings('.js-googleMap__fadeOnInit').fadeOut( 250 );
	} );

	$mapElements.each( function( i, mapElement ) {
		new GoogleMap( $(mapElement), getMarkers( $(mapElement) ) );
	} );

} )( jQuery, window, document );


$(document).ready(function() {
   
    $("#recommend_submit").click( function(event) {
       
        var form = $(this).closest('form'),
            antiSpam = form.find('#url');

        if ( antiSpam.val() !== '' ) {
            antiSpam.addClass('form__input--has-error');
            show_inline_result( antiSpam, 'Leave the spam prevention field blank' );

            event.preventDefault();
            return false;
        }        

        var name = form.find('#recommend_name'),

            company = form.find('#recommend_company'),

            email = form.find('#recommend_email'),
            email_re = /^[A-Z0-9._%+-]+@(?:[A-Z0-9-]+\.)+[A-Z]{2,4}$/i,

            phone = form.find('#recommend_phone'),
            stripped_phone = phone.val().replace(/[^0-9+]/g, ''),

            proceed = true;


        // START VALIDATION

        if( name.is('[required]') && name.val() === '' ) {
            name.addClass('form__input--has-error');
            show_inline_result( name, 'Enter your name' );

            proceed = false;
        }
        if ( name.val() !== '' && name.val().length <= 1 ) {
            name.addClass('form__input--has-error');
            show_inline_result( name, 'Name should be at least 2 characters' );
            
            proceed = false;
        }


        if ( company.val() !== '' && company.val().length <= 1 ) {
            company.addClass('form__input--has-error');
            show_inline_result( company, 'Company name should be at least 2 characters' );
            
            proceed = false;
        }


        if( email.is('[required]') && email.val() === '' ) {
            email.addClass('form__input--has-error');
            show_inline_result( email, 'Enter your email address' );
            
            proceed = false;
        }
        if ( email.val() !== '' && !email_re.test( email.val() ) ) {
            email.addClass('form__input--has-error');
            show_inline_result( email, 'Enter a valid email address' );

            proceed = false;
        }


        if( phone.is('[required]') && phone.val() === '' ) {
            phone.addClass('form__input--has-error');
            show_inline_result( phone, 'Enter your phone number' );

            proceed = false;
        }
        if ( phone.val() !== '' && stripped_phone.length < 9 ) {
            phone.addClass('form__input--has-error');
            show_inline_result( phone, 'Enter a valid phone number' );

            proceed = false;
        }


        if( enquiry.is('[required]') && enquiry.val() === '' ) {
            enquiry.addClass('form__input--has-error');
            show_inline_result( enquiry, 'Enter an enquiry' );
            
            proceed = false;
        }
        if ( enquiry.val() !== '' && enquiry.val().length <= 5 ) {
            enquiry.addClass('form__input--has-error');
            show_inline_result( enquiry, 'Enquiry should be at least 5 characters' );

            proceed = false;
        }

        // END VALIDATION


        if( !proceed ) {
            // Transfer focus from submit button to first error field
            var error_fields = $('.js-form-message--error').filter(':visible');

            if ( error_fields.length ) {
                var $first_error = error_fields.eq(0),
                    $first_error_input = $first_error.siblings('input,textarea,select'),
                    offset = 0;

                if ( $first_error_input.length > 0 ) {
                    offset = $first_error_input.offset().top;
                } else {
                    offset = $first_error.offset().top;
                }

                smooth_scroll( offset - 250, 250 );
                setTimeout(function() {
                    if ( $first_error_input.length > 0 ) {
                        $first_error_input.focus();
                    }
                }, 250);
            }

            event.preventDefault();
            return false;
        }
    });

    //reset previously set border color after entering text
    $('input, textarea, select').on( 'change', function() {
        $(this).removeClass('form__input--has-error');

        if ( $(this).siblings('.js-form-message--error').length ) {
            $(this).siblings('.js-form-message--error').slideUp(250);
        }
    });

    function smooth_scroll( y, speed ) {
        speed = speed || 250;

        $('html, body').stop().animate({
            scrollTop: y
        }, speed);
    }

    function show_inline_result( field, message, status ) {
        status = 'error' || status;

        var msg_container = field.siblings('.js-form-message'),
            msg_container_html = '<p class="form__message form__message--'+status+' js-form-message js-form-message--'+status+'" style="display:none;">' + message + '</p>';

        if ( msg_container.length > 0 ) {

            if ( msg_container.is(':visible') ) {
                msg_container.html( message ).hide().fadeIn(250);
            } else {
                msg_container.html( message ).hide().slideDown(250);
            }

        } else {

            msg_container = $( msg_container_html );
            msg_container.insertAfter( field ).slideDown(250);

        }

    }
});

$(document).ready(function() {
   
    $(document).on( 'click', "#registration_submit-featherlight", function(event) {
       
        var form = $(this).closest('form'),
            $featherlight = $(this).closest('.featherlight'),
            suffix = ( $featherlight.length ? '-featherlight' : '' ),
            antiSpam = form.find('#url' + suffix);

        if ( antiSpam.val() !== '' ) {
            antiSpam.addClass('form__input--has-error');
            show_inline_result( antiSpam, 'Leave the spam prevention field blank' );

            event.preventDefault();
            return false;
        }        

        var name = form.find('#registration_first_name' + suffix),
            surname = form.find('#registration_last_name' + suffix),

            email = form.find('#registration_email' + suffix),
            email_re = /^[A-Z0-9._%+-]+@(?:[A-Z0-9-]+\.)+[A-Z]{2,4}$/i,

            password = form.find('#registration_password' + suffix),

            proceed = true;


        // START VALIDATION

        if( name.is('[required]') && name.val() === '' ) {
            name.addClass('form__input--has-error');
            show_inline_result( name, 'Enter your first name' );

            proceed = false;
        }
        if ( name.val() !== '' && name.val().length <= 1 ) {
            name.addClass('form__input--has-error');
            show_inline_result( name, 'First name should be at least 2 characters' );
            
            proceed = false;
        }


        if( surname.is('[required]') && surname.val() === '' ) {
            surname.addClass('form__input--has-error');
            show_inline_result( surname, 'Enter your surname' );

            proceed = false;
        }
        if ( surname.val() !== '' && surname.val().length <= 1 ) {
            surname.addClass('form__input--has-error');
            show_inline_result( surname, 'Surname should be at least 2 characters' );
            
            proceed = false;
        }


        if( email.is('[required]') && email.val() === '' ) {
            email.addClass('form__input--has-error');
            show_inline_result( email, 'Enter your email address' );
            
            proceed = false;
        }
        if ( email.val() !== '' && !email_re.test( email.val() ) ) {
            email.addClass('form__input--has-error');
            show_inline_result( email, 'Enter a valid email address' );

            proceed = false;
        }
        

        if( password.is('[required]') && password.val() === '' ) {
            password.addClass('form__input--has-error');
            show_inline_result( password, 'Enter a password' );

            proceed = false;
        }
        if ( password.val() !== '' && password.val().length <= 7 ) {
            password.addClass('form__input--has-error');
            show_inline_result( password, 'Password should be at least 8 characters' );
            
            proceed = false;
        }


        // END VALIDATION


        if( !proceed ) {
            // Transfer focus from submit button to first error field
            var error_fields = $('.js-form-message--error').filter(':visible');

            if ( error_fields.length ) {
                var $first_error = error_fields.eq(0),
                    $first_error_input = $first_error.siblings('input,textarea,select'),
                    offset = 0;

                if ( $first_error_input.length > 0 ) {
                    offset = $first_error_input.offset().top;
                } else {
                    offset = $first_error.offset().top;
                }

                smooth_scroll( offset - 250, 250 );
                setTimeout(function() {
                    if ( $first_error_input.length > 0 ) {
                        $first_error_input.focus();
                    }
                }, 250);
            }

            event.preventDefault();
            return false;
        }
    });

    //reset previously set border color after entering text
    $('input, textarea, select').on( 'change', function() {
        $(this).removeClass('form__input--has-error');

        if ( $(this).siblings('.js-form-message--error').length ) {
            $(this).siblings('.js-form-message--error').slideUp(250);
        }
    });

    function smooth_scroll( y, speed ) {
        speed = speed || 250;

        $('html, body').stop().animate({
            scrollTop: y
        }, speed);
    }

    function show_inline_result( field, message, status ) {
        status = 'error' || status;

        var msg_container = field.siblings('.js-form-message'),
            msg_container_html = '<p class="form__message form__message--'+status+' js-form-message js-form-message--'+status+'" style="display:none;">' + message + '</p>';

        if ( msg_container.length > 0 ) {

            if ( msg_container.is(':visible') ) {
                msg_container.html( message ).hide().fadeIn(250);
            } else {
                msg_container.html( message ).hide().slideDown(250);
            }

        } else {

            msg_container = $( msg_container_html );
            msg_container.insertAfter( field ).slideDown(250);

        }

    }
});

// Kick start the processes

$( document ).ready( function(){
	$( '.menu-toggle' ).on( 'touchstart click', function touchStart( event ) {
    $( this ).toggleClass( 'menu-toggle--is-active' );
		$( '.nav' ).slideToggle( 400 );
 	
   	event.preventDefault();
	});

	$( '.js-smooth-scroll' ).click(function(){
   	$( 'html, body' ).animate({ scrollTop: $( $( this ).attr( 'href' ) ).offset().top }, 'slow' );

    return false;
	});

	$( '.toolbar-button--toggle' ).on( 'touchstart click', function touchStart( event ) {
    $( this ).toggleClass( 'toolbar-button--toggle--active' );
		$( '.toolbar-button__list' ).slideToggle( 400 );
 	
   	event.preventDefault();
	});

	// Date Range Picker
	( function( $, window, document, undefined ) {
		
		var $daterangepicker = $('.js-daterangepicker');
		
		if( $daterangepicker.length ){
			$daterangepicker
				.dateRangePicker( {
					format: 'DD/MM/YYYY',
					startDate: moment().format('DD/MM/YYYY'),
					startOfWeek: 'monday',
					inline: true,
					container: '#holiday-daterange-picker',
					alwaysOpen: true
					//TODO: use beforeShowDay: function(t) to disable already selected dates
				} )
				.on( 'datepicker-change', function( event, obj ) {
					var $form = $(this).closest('form'),
							$date_start = $form.find('#date_start'),
							$date_end = $form.find('#date_end');

					$date_start.val( moment( obj.date1 ).format( 'YYYYMMDD' ) );
					$date_end.val( moment( obj.date2 ).format( 'YYYYMMDD' ) );

					removeInlineError( $daterangepicker );
				} );
		}

		/**
		 * Create an inline error message to display to the user.
		 * The error message will be inserted after the element passed.
		 * @param  {jQuery} $after  The element to insert the error message after.
		 * @param  {string} message The message to show.
		 */
		function showInlineError( $after, message ) {
		  var $msg_container = $after.next('.js-form-message'),
		      msg_container_html = '<p class="form__group form__message form__message--error js-form-message js-form-message--error">' + message + '</p>';

		  if ( $msg_container.length > 0 ) {

		    $msg_container.html( message );

		  } else {

		    $msg_container = $( msg_container_html );
		    $msg_container.insertAfter( $after );

		  }

		  // It may be hidden so make sure it is not
		  $msg_container.show();
		}

		/**
		 * Remove an inline error if it exists after a certain element.
		 * @param  {jQuery} $after The error will be removed if it occurs directly after this element.
		 */
		function removeInlineError( $after ) {
			var $msg_container = $after.next('.js-form-message');

			if ( $msg_container.length ) {
				$msg_container.remove();
			}
		}

		$('#request-holiday-form').on( 'submit', function( event ) {
			var $form = $(this),
					$date_start = $form.find('#date_start'),
					$date_end = $form.find('#date_end'),
					proceed = true;

			if ( $date_start.is('[required]') && $date_start.val() === '' ) {
        $date_start.addClass('form__input--has-error');
        showInlineError( $daterangepicker, 'Choose your dates' );

        proceed = false;
      }

      if ( $date_end.is('[required]') && $date_end.val() === '' ) {
        $date_end.addClass('form__input--has-error');
        showInlineError( $daterangepicker, 'Choose your dates' );

        proceed = false;
      }

      if ( !proceed ) {
      	event.preventDefault();
      	return false;
      }
		} );
		
	} )( jQuery, window, document );

	// Slick slider module
	( function( $, window, document, undefined ) {
		
		var $caseStudies = $('.slider-case-studies');
		$caseStudies.customslider();

		$( '.slider-team' ).slick({
			slidesToShow: 4,
			slidesToScroll: 1,
			infinite: true,
			arrows: false,
			autoplay: true,
			speed: 1800,
			cssEase: 'linear',
			autoplaySpeed: 1,
			easing: 'linear',
			pauseOnHover: true,
		  responsive: [
		    {
		      breakpoint: 1024,
		      settings: {
		        slidesToShow: 3,
		      }
		    },
		    {
		      breakpoint: 800,
		      settings: {
		        slidesToShow: 2,
		        speed: 3600
		      }
		    },
		    {
		      breakpoint: 480,
		      settings: {
		        slidesToShow: 1,
		        slidesToScroll: 1,
		        speed: 5000
		      }
		    }
		  ]
		});
		
		$( '.slider-videos' ).slick({
			slidesToShow: 3,
			slidesToScroll: 1,
			infinite: true,
			arrows: true,
			autoplay: true,
			speed: 1800,
			cssEase: 'linear',
			autoplaySpeed: 1,
			easing: 'linear',
			pauseOnHover: true,
		  responsive: [
		    {
		      breakpoint: 1024,
		      settings: {
		        slidesToShow: 3,
		      }
		    },
		    {
		      breakpoint: 940,
		      settings: {
		        arrows: false,
		      }
		    },
		    {
		      breakpoint: 800,
		      settings: {
		        slidesToShow: 2,
		        arrows: false,
		      }
		    },
		    {
		      breakpoint: 480,
		      settings: {
		        slidesToShow: 1,
		        arrows: false
		      }
		    }
		  ]
		});

		/**
		 * Repeat text at the start of a string until the string reaches a certain length.
		 * @param  {String} string  The string to pad.
		 * @param  {String} padWith The text to repeat at the start of string.
		 * @param  {Number} length  The desired length of the return string.
		 * @return {String}         The padded string.
		 */
		function padLeft( string, padWith, length ) {
			// Cast params to string
			string += "";
			padWith += "";

			// Return early if the string is the correct length already
			if ( string.length >= length ) {
				return string;
			}

			while	( string.length < length ) {
				string = padWith + string;
			}

			return string;
		}

		function updateSliderControls( selector, action ) {
			var $target = $( selector ),
					$controls = $( '[data-slider-control]' ),
					$index = $controls.filter( '[data-slider-control="slide-index"]' ).filter( '[data-slider-target="' + selector + '"]' );

			// Update index
			if ( $index.length ) {
				$index.text( padLeft( $target.customslider( 'currentIndex' ) + 1, '0', 2 ) );
			}
		}

		$( '[data-slider-action]' ).click( function( event ) {
			var $this = $(this);

			// Disable button until transition has finished
			$(this).attr( 'disabled', 'disabled' );
			setTimeout(function() {
				$this.removeAttr( 'disabled' );
			}, 600);

			var action = $this.data( 'sliderAction' ),
					targetSelector = $this.data( 'sliderTarget' ),
					$target = $( targetSelector );

			if ( $target.length && $target.hasClass( 'customslider-initialised' ) ) {
				
			  $target.customslider( action );

			  // Update controls
			  updateSliderControls( targetSelector, action );

			}

			event.preventDefault();
			return false;
		});
		

	} )( jQuery, window, document );

	// Job Filter logic
	$('a[data-job-type-filter]').click( function( event ) {

		var $this = $(this),
				job_type = $this.data('jobTypeFilter'),
				$allJobs = $('[data-job-type]');
				$relatedJobs = $allJobs.filter('[data-job-type="' + job_type + '"]');

		if ( $relatedJobs.length ) {

			$allJobs.hide().attr( 'aria-hidden', true );
			$allJobs.css( 'margin-top', '' );
			
			$relatedJobs.eq(0).css( 'margin-top', 0 );
			$relatedJobs.show().attr( 'aria-hidden', false );

		} else {

			if ( '*' == job_type ) {
				$allJobs.css( 'margin-top', '' );
				$allJobs.show().attr( 'aria-hidden', false );
			} else {
				alert( 'No ' + job_type + ' jobs found.' );
			}

		}

		$this.parent().siblings('li').removeClass( 'is-active' );
		$this.parent().addClass( 'is-active' );

		event.preventDefault();
		return false;

	} );

	// Case Studies filter
	( function( $, window, document, undefined ) {
		
		var $caseStudies = $('.js-case-study'),
				$filters = $('[data-sector-term-filter]'),
				$filterButton = $('.js-case-study-filter-button'),
				$foundPosts = $('.js-found-posts'),
				activeTermIDs = [];

		function updatePostCount( count ) {
			if ( $foundPosts.length ) {
				$foundPosts.html( _n( count + ' case study found', count + ' case studies found', count ) );
			}

			if ( 0 === count ) {
				$('.js-case-study-none').show();
			} else {
				$('.js-case-study-none').hide();
			}
		}

		function hideAllCaseStudies() {
			$caseStudies.hide();
		}

		function showAllCaseStudies() {
			$caseStudies.show();
		}

		function showMatchingCaseStudies() {
			$caseStudies.each( function( index, node ) {

				var $this = $(this),
						sectors = JSON.parse( $this.attr('data-sectors') );

				$.each( activeTermIDs, function( key, value ) {

					if ( $.inArray( value, sectors ) > -1 ) {
						$this.show();
					}

				} );

			} );
		}

		if ( $caseStudies.length ) {

			$filters.on( 'click', function( event ) {

				var $this = $(this),
						termID = parseInt( $this.attr('data-sector-term-filter') ),
						index;

				if ( $.inArray( termID, activeTermIDs ) > -1 ) {

					index = activeTermIDs.indexOf( termID );
					if ( index > -1 ) {
						activeTermIDs.splice( index, 1 );
					}

				} else {

					activeTermIDs.push( termID );

				}

				if ( activeTermIDs.length > 0 ) {

					hideAllCaseStudies();
					showMatchingCaseStudies();

				} else {

					showAllCaseStudies();

				}
				
				updatePostCount( $caseStudies.filter(':visible').length );
				
			} );

			$filterButton.closest( '.sectors-filter' ).css( 'min-height', 0 );
			$filterButton.hide();

		}

	} )( jQuery, window, document );

	// Init marquee scrollers once images have loaded
  $('.js-marquee').imagesLoaded( function() {

  	$('.js-marquee').marquee( {
  		//speed in milliseconds of the marquee
      duration: 22000,
      //gap in pixels between the tickers
      gap: 32,
      //time in milliseconds before the marquee will start animating
      delayBeforeStart: 500,
      //'left' or 'right'
      direction: 'left',
      //true or false - should the marquee be duplicated to show an effect of continues flow
      duplicated: true,
      // Start with the marquee content visible
      startVisible: true,
  	} );

  } );

});
